import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Typography } from "@material-ui/core";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import BundleModel from "../../../model/bundle";
import BundleModal from "./BundleModal";
import BundleItem from "../../../model/bundleItem";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  order: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  bundle: BundleModel;
  handleUpdate: any;
  handleDelete: any;
}

const BundleDetailModal = ({ bundle, handleUpdate, handleDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Bundle details</Typography>
            <div className={classes.headerSubDiv}>
              <BundleModal
                bundle={bundle}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <form className={classes.form}>
            {bundle.imageUrl && (
              <img
                className={classes.imageDetail}
                src={bundle.imageUrl}
                alt="product img"
              />
            )}
            <input
              className={classes.inputDetail}
              value={bundle.name}
              disabled
            />
            <textarea
              className={classes.inputDetail}
              value={bundle.description}
              disabled
            />
            {bundle?.BundleItems && bundle?.BundleItems?.length > 0 && (
              <div className="table-container">
                <table className="styled-table">
                  <thead>
                    <td>Product/Service</td>
                    <td>Quantity</td>
                  </thead>
                  <tbody>
                    {bundle?.BundleItems?.map((item: BundleItem) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="text"
                            min={0}
                            value={item.Product?.name}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            min={0}
                            value={item.quantity}
                            disabled
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default BundleDetailModal;
