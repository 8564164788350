import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Navbar,
  Homepage,
  Errorpage,
  Service,
  Review,
  RequestService,
  About,
  Contact,
  Gallery,
  TermsAndConditions,
  PrivacyPolicy,
  Appointment,
  Finance,
  Footer,
  Cookies,
  ThanksPage
} from "./components/";
import { Admin } from "./administration";
import { CssBaseline } from "@mui/material";
import Pathnames from "./model/enum/Pathnames";
import TrackPathForAnalytics from "./service/TrackPathForAnalytics";
import ReactGA from "react-ga4";
import "./App.css";

ReactGA.initialize("AW-16663821488");

const App = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const { search } = useLocation();
  const location = useLocation();

  let pathname = location.pathname;

  const analytics = useCallback(() => {
    TrackPathForAnalytics({
      path: pathname,
      search: search,
      title: pathname.split("/")[1],
    });
  }, [pathname, search]);

  useEffect(() => {
    analytics();
  }, [analytics]);

  useEffect(() => {
    ReactGA.send(pathname + search);
  }, [location]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        {!pathname.includes("/admin") ? (
          <>
            <header>{showNavbar && <Navbar />}</header>
            <Routes>
              <Route path={Pathnames.HOMEPAGE} element={<Homepage />} />
              <Route path={Pathnames.REQUEST} element={<RequestService />} />
              <Route
                path={Pathnames.GALLERY}
                element={
                  <Gallery
                    handleNavbar={(state: boolean) => setShowNavbar(state)}
                  />
                }
              />
              <Route path={Pathnames.REVIEWS} element={<Review />} />
              <Route path={Pathnames.ABOUT} element={<About />} />
              <Route path={Pathnames.CONTACT} element={<Contact />} />
              <Route path={Pathnames.WILDCARD} element={<Errorpage />} />
              <Route path={Pathnames.SERVICE + "/*"} element={<Service />} />
              <Route path={Pathnames.TERMS} element={<TermsAndConditions />} />
              <Route path={Pathnames.PRIVACY} element={<PrivacyPolicy />} />
              <Route path={Pathnames.SCHEDULE} element={<Appointment />} />
              <Route path={Pathnames.FINANCE} element={<Finance />} />
              <Route path={Pathnames.THANKYOU} element={<ThanksPage />} />
            </Routes>
            <Cookies />
            <Footer />
          </>
        ) : (
          <>
            <Admin />
          </>
        )}
      </div>
    </>
  );
};

export default App;
