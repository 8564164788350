import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Tabs, Tab } from "@mui/material";
import {
  Button as Btn,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Spinner } from "../../components";
import {
  Black,
  DarkGrey,
  LightGrey,
  LightPrimaryColor,
  PrimaryColor,
} from "../../shared/styles/colors";
import DeleteModal from "../modal/DeleteModal";

import UserModel from "../../model/user";
import StatusModel from "../../model/status";
import ClientModel from "../../model/client";
import EstimateModel from "../../model/estimate";
import BundleModel from "../../model/bundle";
import ProductModel from "../../model/product";
import BundleItemModel from "../../model/bundleItem";

import MainLogo from "../../assets/main.png";
import TransformDate from "../../utils/TransformDate";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/admin/edit-icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClientModal from "../client/ClientModal";
import useStyles from "./styles";
import "../styles/tableStyles.css";

const style = {
  position: "absolute" as "absolute",
  top: "5%", // Start from a fixed top position
  left: "50%",
  transform: "translateX(-50%)", // Only horizontally center the modal
  width: "85%",
  maxWidth: "85rem",
  bgcolor: "#F9F9F9",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "85%",
  },
};

//TODO: fix other modals to this concept

type Item = {
  id: number;
  task: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
};

type Task = {
  id: number;
  task: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  BundleItems: Array<BundleItemModel>;
};

const EstimateModal = ({ estimate, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [value, setValue] = useState(0); // Active tab

  const edit = estimate != null;
  const id = edit ? estimate.id : 0;
  const [code, setCode] = useState(edit ? estimate.code : generateKey());
  const [client, setClient] = useState(edit ? estimate.Client : null);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [dueDate, setDueDate] = useState(edit ? estimate.dueDate : null);
  const [status, setStatus] = useState(edit ? estimate.Status : null);
  const [statusList, setStatusList] = useState<StatusModel[]>([]);
  const [user, setUser] = useState(edit ? estimate.user : null);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [notes, setNotes] = useState(edit ? estimate.notes : null);
  const [approved, setApproved] = useState(edit ? estimate.approved : false);
  const [acceptedBy, setAcceptedBy] = useState(
    edit ? estimate.acceptedBy : null
  );
  const [acceptedAt, setAcceptedAt] = useState(
    edit ? estimate.acceptedAt : null
  );
  const [startsAt, setStartsAt] = useState(edit ? estimate.startsAt : null);
  const [urgentPayment, setUrgentPayment] = useState(
    edit ? estimate.urgentPayment : null
  );
  const createdAt = edit ? new Date(estimate.createdAt) : new Date();
  const [total, setTotal] = useState(edit ? estimate.total : null);
  const [discount, setDiscount] = useState();
  const [bundles, setBundles] = useState<BundleModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [items, setItems] = useState<Task[]>(
    edit
      ? estimate.items
      : [
          {
            id: 1,
            task: "",
            description: "",
            quantity: 0,
            rate: 0,
            amount: 0,
            BundleItems: [],
          },
        ]
  );

  //Get clients list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          setClients(data);
        }
      );

      setToggleSpinner(false);
    };

    if (clients.length <= 0 && open) fetchData();
  }, [open, clients]);

  //Get users list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<UserModel[]>(`${DatabaseEntities.USERS}`).then(
        (data: UserModel[]) => {
          setUsers(data);
        }
      );

      setToggleSpinner(false);
    };

    if (users.length <= 0 && open) fetchData();
  }, [open, users]);

  //Get status list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<StatusModel[]>(
        `${DatabaseEntities.STATUS}?type=Estimates`
      ).then((data: StatusModel[]) => {
        setStatusList(data);
      });

      setToggleSpinner(false);
    };

    if (statusList.length <= 0 && open) fetchData();
  }, [open, statusList]);

  // Get all the categories items from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<BundleModel[]>(DatabaseEntities.BUNDLES).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);
          setBundles(data);
        }
      );
      setToggleSpinner(false);
    };

    if (bundles.length <= 0) {
      fetchData();
    }
  }, [open, bundles]);

  // Get all the products items from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);
      await ApiService.get<ProductModel[]>(DatabaseEntities.PRODUCTS).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);
          setProducts(data);
        }
      );
      setToggleSpinner(false);
    };

    if (products.length <= 0) {
      fetchData();
    }
  }, [open, products]);

  const handleChange = (_event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleClient = (e: any) => {
    let value = e.target.value;
    var clt = clients.find((x) => x.id == value);
    setClient(clt);
  };

  const handleStatus = (e: any) => {
    let value = e.target.value;
    var stt = statusList.find((x) => x.id == value);
    setStatus(stt);
  };

  const handleAddItem = () => {
    const newItem: Task = {
      id: items.length + 1,
      task: "",
      description: "",
      quantity: 0,
      rate: 0,
      amount: 0,
      BundleItems: [],
    };
    setItems([...items, newItem]);
  };

  const handleTask = (e: any, index: number) => {
    //const task: ProductModel = products[index];
    const task: BundleModel = bundles[index];

    //update task info based on the bundle info
    const updatedItems = [...items];

    //remove old talk if switched
    //items.pop();

    updatedItems[index].task = e.target.value;
    updatedItems[index].description = task.description ?? "";
    updatedItems[index].BundleItems = task.BundleItems ?? [];

    //vars to calculate the totals
    let totalRate: number = 0;
    let totalQuantity: number = 0;
    let totalAmount: number = 0;

    //map the child items to calculate the total amount, quantity and rate of the bundle
    task.BundleItems?.map((item: BundleItemModel) => {
      totalRate += item.Product?.price ? parseInt(item.Product.price) : 0;
      totalQuantity += item.quantity;
      totalAmount += item.Product?.price
        ? item.Product.price * item.quantity
        : 0;
    });
    updatedItems[index].rate = totalRate;
    updatedItems[index].quantity = totalQuantity;
    updatedItems[index].amount = totalAmount;

    setItems(updatedItems);
  };

  const handleItem = (e: any, index: number, pos: number) => {
    const updatedItems = [...items];
    updatedItems[index].BundleItems[pos].Product.name = e.target.value;
    setItems(updatedItems);
  };

  const handleDescription = (e: any, index: number, pos?: number) => {
    let description = e.target.value;

    const updatedItems = [...items];
    if (pos != undefined)
      updatedItems[index].BundleItems[pos].Product.description = description;
    else updatedItems[index].description = description;

    setItems(updatedItems);
  };

  const handleQuantity = (e: any, index: number, pos?: number) => {
    //debugger;
    const updatedItems = [...items];
    let value = parseInt(e.target.value);
    if (pos != undefined){
      updatedItems[index].BundleItems[pos].quantity = value;
      //updatedItems[index].amount = value * updatedItems[index].rate;
    } 
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleRate = (e: any, index: number, pos?: number) => {
    const updatedItems = [...items];
    let value = parseInt(e.target.value);
    if (pos != undefined){
      updatedItems[index].BundleItems[pos].Product.price = value;
    //updatedItems[index].BundleItems[pos]. = value * updatedItems[index].quantity;
    }
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleAmount = (e: any, index: number, pos?: number) => {
    const updatedItems = [...items];
    if (pos != undefined){
      updatedItems[index].BundleItems[pos].Product.price =
        parseInt(e.target.value) || 0;
    }
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleDeleteTask = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleDeleteItem = (index: number, pos: number) => {
    const task: BundleModel = bundles[index];
    const updatedItems = [...items];
    const newItems = task.BundleItems?.filter((_, i) => i !== pos);

    updatedItems[index].BundleItems = newItems ?? [];

    setItems(updatedItems);

    calculateBundleTotal(index, updatedItems);

    if (newItems && newItems.length == 0) handleDeleteTask(index);
  };

  const calculateBundleTotal = (index: number, updatedItems: Task[]) => {
    //vars to calculate the totals
    let totalRate: number = 0;
    let totalQuantity: number = 0;
    let totalAmount: number = 0;

    //map the child items to calculate the total amount, quantity and rate of the bundle
    updatedItems[index].BundleItems.map((item: BundleItemModel) => {
      totalRate += item.Product?.price ? parseInt(item.Product.price) : 0;
      totalQuantity += item.quantity;
      totalAmount += item.Product?.price
        ? item.Product.price * item.quantity
        : 0;
    });
    updatedItems[index].rate = totalRate;
    updatedItems[index].quantity = totalQuantity;
    updatedItems[index].amount = totalAmount;

    setItems(updatedItems);
  };

  //Calculate the total amount of the estimate everytime the amount, rate or quantity changes
  useEffect(() => {
    let val = 0;
    items.map((x) => {
      val += x.amount;
    });
    setTotal(val);
  }, [handleAmount, handleRate, handleQuantity, handleDeleteItem]);

  const invalidInput = (): boolean => {
    if (code && client) return true;

    // Confirm if all thw items has a productId and a quantity
    if (items.length > 0)
      return (
        items.some((x) => x.task == "") || items.some((x) => x.quantity == 0)
      );

    return false;
  };

  const submit = async () => {
    if (invalidInput()) return;
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Estimate
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <Card style={{ padding: "25px" }}>
              <div className={classes.headerDiv}>
                {edit ? (
                  <Typography className={classes.title}>
                    <DeleteModal item={estimate} handleDelete={handleDelete} />
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: DarkGrey,
                      textAlign: "center",
                      width: "auto",
                      fontSize: "1.1rem",
                    }}
                  >
                    Estimate
                  </Typography>
                )}
                <Button onClick={handleClose} sx={{ color: "black" }}>
                  <CloseIcon />
                </Button>
              </div>
              <div>
                <hr />
                <Box
                  sx={{ width: "20%", borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    variant="standard"
                    textColor="inherit"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                      sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
                    }}
                  >
                    <Tab
                      label="Edit"
                      sx={{
                        opacity: value === 0 ? 1 : 0.5,
                        textTransform: "capitalize",
                      }}
                    />
                    <Tab
                      label="PDF View"
                      sx={{
                        opacity: value === 1 ? 1 : 0.5,
                        textTransform: "capitalize",
                      }}
                    />
                  </Tabs>
                </Box>
              </div>
            </Card>
            <form className={classes.form}>
              {/** Form */}
              <Card style={{ width: "80%", padding: "5px" }}>
                <CardContent>
                  <div className={classes.headerDiv}>
                    <div>
                      <Typography
                        style={{
                          color: Black,
                          fontSize: "2rem",
                          fontWeight: "700",
                        }}
                      >
                        Estimate
                      </Typography>
                      <Typography className="">{`#${code}`}</Typography>
                    </div>
                    <img src={MainLogo} alt="logo" width={120} height={50} />
                  </div>
                  <div>
                    <Typography>Setup Remodeling Inc.</Typography>
                    <Typography>72 Cash St.</Typography>
                    <Typography>Fall River Ma 02723</Typography>
                  </div>
                  <div>
                    <Typography>sales@setupremodeling.com</Typography>
                    <Typography>+1 (800)-530-4099</Typography>
                    <Typography>wwww.setupremodeling.com</Typography>
                  </div>
                  <div className={classes.inputGroup}>
                    <select
                      className={classes.input}
                      onChange={handleClient}
                      value={client?.id}
                    >
                      <option value="">Select Client</option>
                      {clients.map((clt: ClientModel) => {
                        return (
                          <option key={clt.id} value={clt.id}>
                            {clt.firstName} {clt.lastName}
                          </option>
                        );
                      })}
                    </select>
                    <ClientModal
                      externalService={true}
                      resetList={() => setClients([])}
                      setClient={(clt: ClientModel) => setClient(clt)}
                    />
                  </div>
                  <hr />
                  <div className="table-container">
                    <table className="styled-table">
                      <thead style={{ color: "rgba(117, 117, 117, 1)" }}>
                        <td>Task</td>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Rate</td>
                        <td>Amount</td>
                        <td>Action</td>
                      </thead>
                      <tbody>
                        {items.map((row, index) => (
                          <React.Fragment key={row.id}>
                            {/* Parent Row */}
                            <tr key={row.id}>
                              <td>
                                <select
                                  className={classes.input}
                                  value={row.task}
                                  onChange={(e) => handleTask(e, index)}
                                  disabled={items[index].task != ""}
                                >
                                  <option value="" disabled>
                                    Select task
                                  </option>
                                  {bundles.map((bnd: BundleModel) => {
                                    return (
                                      <option key={bnd.id} value={bnd.name}>
                                        {bnd.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Add a description.."
                                  value={items[index].description}
                                  onChange={(e) => handleDescription(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].quantity}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleQuantity(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].rate}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleRate(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].amount}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleAmount(e, index)}
                                />
                              </td>
                              <td>
                                <Button
                                  className="delete-btn"
                                  onClick={() => handleDeleteTask(index)}
                                >
                                  🗑️
                                </Button>
                              </td>
                            </tr>
                            {/* Nested Rows */}
                            {row.BundleItems.map((child, pos) => (
                              <tr key={child.id}>
                                <td
                                  style={{
                                    paddingLeft: "30px",
                                  }}
                                >
                                  <select
                                    className={classes.input}
                                    style={{
                                      background: "#F1F2F3",
                                      color: "grey",
                                    }}
                                    value={child.Product?.name}
                                    onChange={(e) => handleItem(e, index, pos)}
                                    disabled={items[index].BundleItems[pos].Product.name != ""}
                                  >
                                    <option value="" disabled>
                                      Select task
                                    </option>
                                    {products.map((prod: ProductModel) => {
                                      return (
                                        <option key={prod.id} value={prod.name}>
                                          {prod.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    style={{
                                      background: "#F1F2F3",
                                      color: "grey",
                                    }}
                                    type="text"
                                    placeholder="Add a description.."
                                    value={child.Product.description}
                                    onChange={(e) =>
                                      handleDescription(e, index, pos)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    style={{
                                      background: "#F1F2F3",
                                      color: "grey",
                                    }}
                                    type="number"
                                    placeholder="01"
                                    min={1}
                                    value={child.quantity}
                                    onChange={(e) =>
                                      handleQuantity(e, index, pos)
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    style={{
                                      background: "#F1F2F3",
                                      color: "grey",
                                    }}
                                    type="number"
                                    placeholder="01"
                                    min={1}
                                    value={child.Product.price}
                                    onChange={(e) => handleRate(e, index, pos)}
                                  />
                                </td>
                                <td>
                                  <input
                                    style={{
                                      background: "#F1F2F3",
                                      color: "grey",
                                    }}
                                    type="number"
                                    placeholder="01"
                                    min={1}
                                    value={child.Product.price * child.quantity}
                                    onChange={(e) =>
                                      handleAmount(e, index, pos)
                                    }
                                  />
                                </td>
                                <td>
                                  <Button
                                    className="delete-btn"
                                    onClick={() => handleDeleteItem(index, pos)}
                                  >
                                    🗑️
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                        <tr className="add-item-row">
                          <td colSpan={6} className="add-item">
                            <span onClick={handleAddItem}>+ Add Item</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardContent>
              </Card>
              {/** Details */}
              <Card
                style={{ width: "24%", marginLeft: "15px", padding: "5px" }}
              >
                <CardContent>
                  <Typography>Details</Typography>
                  <div>
                    <Typography>Estimate total:</Typography>
                    <Typography>${total}</Typography>
                  </div>
                  <div>
                    <Typography>Due Date:</Typography>
                    <Typography>July 17, 2024</Typography>
                  </div>
                  <div>
                    <Typography>Status:</Typography>
                    <select
                      className={classes.input}
                      onChange={handleStatus}
                      value={status?.id}
                    >
                      <option value="">Select status</option>
                      {statusList.map((stts) => {
                        return (
                          <option key={stts.id} value={stts.id}>
                            {stts.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <Typography>Created date:</Typography>
                    <Typography>
                      {TransformDate.toFullDateHours(createdAt)}
                    </Typography>
                  </div>
                  <div>
                    <Typography>Created time:</Typography>
                    <Typography>3:23 PM</Typography>
                  </div>
                </CardContent>
              </Card>
            </form>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default EstimateModal;

function generateKey(): number {
  // Generate a random number between 100000 and 999999
  const randomNumber = Math.floor(100000 + Math.random() * 900000);

  return randomNumber;
}
