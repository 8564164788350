import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { useCookies } from "react-cookie";
import { Spinner } from "../../components";
import { White } from "../../shared/styles/colors";
import Pathnames from "../../model/enum/Pathnames";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import ApiService from "../../service/ApiService";
import SmallLogo from "../../assets/icon.png";
import Logo from "../../assets/main.png";
import DashboardIcon from "../../assets/admin/sidebar/dashboard.svg";
import MediaIcon from "../../assets/admin/sidebar/media.svg";
import ContentIcon from "../../assets/admin/sidebar/content.svg";
import GeneralIcon from "../../assets/admin/sidebar/general.svg";
import InternalIcon from "../../assets/admin/sidebar/internal.svg";
import SettingIcon from "../../assets/admin/sidebar/setting.svg";
import LogoutIcon from "../../assets/admin/sidebar/logout.svg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "./styles";

const Side = () => {
  const classes = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies(["session"]);
  const [open, setOpen] = useState(true);
  const [toggleSpinner, setToggleSpinner] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  let pathname = location.pathname;

  const checkLocation = (loc: any) => {
    return pathname === loc;
  };

  const handleCollapse = () => {
    setOpen((open) => !open);
  };

  const logout = async () => {
    setToggleSpinner(true);
    removeCookie("session");
    localStorage.removeItem("email");
    localStorage.removeItem("accessToken");
    await ApiService.post<any>(`${DatabaseEntities.AUTH}/logout`, {});
    setToggleSpinner(false);
    window.open(Pathnames.LOGIN, "_self");
  };

  useEffect(() => {
    if (window.innerWidth <= 640) {
      setOpen(true);
      handleCollapse();
    }
  });

  return (
    <>
      {toggleSpinner && <Spinner />}
      <Sidebar
        id="sidebar-div"
        className={classes.sidebar}
        collapsed={!open}
        backgroundColor={White}
      >
        {open ? (
          <div className="d-flex row">
            <div className={classes.topDiv}>
              <Button onClick={handleCollapse} className={classes.topButton}>
                <CloseIcon />
                {/**Change the condition */}
              </Button>
            </div>
            <img src={Logo} className={classes.fullLogo} alt="logo" />
          </div>
        ) : (
          <div className="d-flex row align-items-center justify-content-center">
            {window.innerWidth > 640 && (
              <Button onClick={handleCollapse}>
                <MenuIcon />
              </Button>
            )}
            <img
              src={SmallLogo}
              className={classes.logo}
              alt="icon"
              onClick={() => navigate(Pathnames.ADMIN)}
            />
          </div>
        )}
        <Menu className={classes.menu} closeOnClick>
          <MenuItem
            component={
              <Link
                to={Pathnames.DASHBOARD}
                className={
                  checkLocation(Pathnames.DASHBOARD) ? classes.selectedMenu : ""
                }
              />
            }
          >
            <Typography
              className={
                checkLocation(Pathnames.DASHBOARD)
                  ? classes.selectedText
                  : classes.text
              }
            >
              <img
                src={DashboardIcon}
                className={classes.icon}
                alt="dashboard"
              />
              {open && <span>Dashboard</span>}
            </Typography>
          </MenuItem>
          <SubMenu
            label={
              <>
                <img src={MediaIcon} className={classes.icon} alt="media" />
                {open && <span>Media Management</span>}
              </>
            }
            className={classes.text}
          >
            <MenuItem component={<Link to={Pathnames.GALLERY_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.GALLERY_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Gallery
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.REVIEWS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.REVIEWS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Reviews
              </Typography>
            </MenuItem>
          </SubMenu>
          <SubMenu
            label={
              <>
                <img src={ContentIcon} className={classes.icon} alt="content" />
                {open && <span>Content Management</span>}
              </>
            }
            className={classes.text}
          >
            <MenuItem component={<Link to={Pathnames.APPOINTMENT_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.APPOINTMENT_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Appointments
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.REQUESTS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.REQUESTS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Requests
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.CONTACTS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.CONTACTS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Messages
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.CLIENTS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.CLIENTS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Clients
              </Typography>
            </MenuItem>
            <MenuItem
              component={<Link to={Pathnames.PRODUCTS_SERVICES_LIST} />}
            >
              <Typography
                className={
                  checkLocation(Pathnames.PRODUCTS_SERVICES_LIST) ||
                  checkLocation(Pathnames.PRODUCTS_SERVICES_BUNDLE_LIST) ||
                  checkLocation(Pathnames.PRODUCTS_SERVICES_CATEGORY_LIST) ||
                  checkLocation(Pathnames.PRODUCTS_SERVICES_VENDOR_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Products/Services
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.ESTIMATES_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.ESTIMATES_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Estimates
              </Typography>
            </MenuItem>
            {/* <MenuItem component={<Link to={Pathnames.TEAM_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.TEAM_LIST) ? classes.selectedMenu : ""
                }
              >
                Teams
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.PROJECTS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.PROJECTS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Projects
              </Typography>
            </MenuItem> */}
          </SubMenu>
          <SubMenu
            label={
              <>
                <img src={GeneralIcon} className={classes.icon} alt="general" />
                {open && <span>General Management</span>}
              </>
            }
            className={classes.text}
          >
            <MenuItem component={<Link to={Pathnames.SERVICES_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.SERVICES_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Services for Request
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.FAQ_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.FAQ_LIST) ? classes.selectedMenu : ""
                }
              >
                FAQ's
              </Typography>
            </MenuItem>
            {/* <MenuItem component={<Link to={Pathnames.STATUS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.STATUS_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Pending Requests
              </Typography>
            </MenuItem> */}
          </SubMenu>
          <SubMenu
            label={
              <>
                <img
                  src={InternalIcon}
                  className={classes.icon}
                  alt="internal"
                />
                {open && <span>Internal Services</span>}
              </>
            }
            className={classes.text}
          >
            <MenuItem component={<Link to={Pathnames.USER_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.USER_LIST) ? classes.selectedMenu : ""
                }
              >
                System Users
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.ROLE_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.ROLE_LIST) ? classes.selectedMenu : ""
                }
              >
                Roles
              </Typography>
            </MenuItem>
            {/* <MenuItem component={<Link to={Pathnames.EMAIL_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.EMAIL_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Emails
              </Typography>
            </MenuItem> */}
            {/* <MenuItem component={<Link to={Pathnames.MESSAGE_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.MESSAGE_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Messages
              </Typography>
            </MenuItem> */}
            <MenuItem component={<Link to={Pathnames.LOGS_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.LOGS_LIST) ? classes.selectedMenu : ""
                }
              >
                Logs
              </Typography>
            </MenuItem>
            <MenuItem component={<Link to={Pathnames.ERROR_LIST} />}>
              <Typography
                className={
                  checkLocation(Pathnames.ERROR_LIST)
                    ? classes.selectedMenu
                    : ""
                }
              >
                Errors
              </Typography>
            </MenuItem>
          </SubMenu>
          <MenuItem
            component={
              <Link
                to={Pathnames.SETTINGS}
                className={
                  checkLocation(Pathnames.SETTINGS) ? classes.selectedMenu : ""
                }
              />
            }
          >
            <Typography
              className={
                checkLocation(Pathnames.SETTINGS)
                  ? classes.selectedText
                  : classes.text
              }
            >
              <img src={SettingIcon} className={classes.icon} alt="setting" />
              {open && <span>Settings</span>}
            </Typography>
          </MenuItem>
          <MenuItem onClick={logout}>
            <Typography className={classes.text}>
              <img src={LogoutIcon} className={classes.icon} alt="logout" />
              {open && <span>Logout</span>}
            </Typography>
          </MenuItem>
        </Menu>
      </Sidebar>
    </>
  );
};

export default Side;
